<template>
  <b-row>

    <b-col cols="12">
      <b-overlay
        id="overlay-background"
        rounded="sm"
      >
        <b-row>
          <b-col
            cols="12"
          >

            <course-progress-card
              :data="GET_ONE_RELATION"
              :type="false"
              :series="Math.round(GET_ONE_COURSE.chapter_statistics)"
              :time-spent="GET_ONE_COURSE.total_time_spent"
            />
          </b-col>
          <b-col cols="12">
            <div
              class="list-group list-group-flush mt-2"
              tag="ul"
            >

              <h2 class="mb-1">
                {{ $t('Question types') }}
              </h2>

              <b-card
                v-for="listItem in list"
                :key="`key-${listItem.id}`"
                action-collapse
                class="m"
                style="cursor: pointer;"
                @click="goToChapters(listItem)"
              >

                <b-row>
                  <b-col cols="12" class="text-lg d-flex justify-content-between" style="font-size: 18px">
                    <div>
                      {{ listItem.title }}
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="btn-icon mr-50 p-25"
                      size="sm"
                      @click="goToChapters(listItem)"
                    >
                      <feather-icon
                        size="22"
                        icon="ChevronsRightIcon"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  // BBadge,
  // BListGroupItem,
  // BCardText,
  // BCollapse,
  VBToggle,
  BCard,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import courseProgressCard from '../components/courseProgressCard.vue'
import extra from '@/mixins/extra'

export default {
  components: {
    BRow,
    BCol,
    // BCardText,
    // BListGroupItem,
    BCard,
    // BBadge,
    BOverlay,
    courseProgressCard,
    BButton,
    // BCollapse,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [extra],
  data() {
    return {
      isStudentResultView: false,
      windowTop: null,
      lessonData: {},
      editedTheme: {},
      lessonParentId: null,
      isEdit: false,
      isChapter: true,
      table: [],
      chapter: null,
      card: {},
      dataToEdit: {},
      lessonId: {},
      allowed_to_view: true,
      show: true,
      list: [],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE', 'GET_COURSE_CHAPTERS', 'GET_ONE_RELATION']),
    getCourseStatistics() {
      return [Math.round(this.GET_ONE_COURSE.chapter_statistics), 100 - Math.round(this.GET_ONE_COURSE.chapter_statistics)]
    },
  },
  async mounted() {
    this.FETCH_ONE_RELATION(this.$route.params.relationId)
    await this.FETCH_ONE_COURSE({ id: this.$route.params.id }).then((res) => {
      this.list = res.form_type
      this.updateBreadcrumb()
    })
  },
  beforeDestroy() {
    this.SET_CHAPTERS([])
    this.SET_ONE_COURSE({})
    this.SET_ONE_RELATION({})
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE', 'FETCH_ONE_RELATION', 'FETCH_LESSON', 'FETCH_ONE_COURSE_FOR_ADMIN']),
    ...mapActions('contacts', ['FETCH_ONE_CONTACT']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapMutations('courses', ['SET_CHAPTERS', 'SET_ONE_COURSE', 'SET_ONE_RELATION']),
    goToChapters(item) {
      this.$router.push(`/course/${this.$route.params.id}/${this.$route.params.relationId}/form/${item.id}`)
    },
    updateBreadcrumb() {
      const [first, second, third] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      third.text = this.GET_ONE_COURSE.title
      this.UPDATE_BREADCRUMB([first, second, third])
    },
  },
}
</script>

<style>
.drag-style {
      padding: 1rem 2.8rem 1rem 1rem;
      margin-bottom: 1rem;
}
.drag-text {
    justify-content: center;
    align-items: center;
}
.min-width-100 {
  min-width: 120px;
}

[dir] .card {
    margin-bottom: 1.5rem;
}
</style>
