<template>
  <b-card
    v-if="data"
    class="earnings-card"
    @click="type ? $router.push(`/course/${data.course.id}/${data.id}`) : ''"
  >
    <b-row>
      <b-col cols="6">
        <small
          v-if="data.course"
          class="text-muted"
        >{{ data.course.subject[$i18n.locale] }}</small>
        <h4
          v-if="data.course"
          class="mb-1"
        >
          {{ data.course.title }}
        </h4>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder"><span> {{ $t('users.number_of_days_left') }}: </span> {{ dataBetween(data.access_start_date, data.access_end_date) }}</span>
        </b-card-text>
        <b-card-text
          v-if="timeSpent"
          class="text-muted font-small-2"
        >
          <span class="font-weight-bolder"><span> {{ $t('courses.time_spent') }}: </span> {{ timeSpent }}</span>
        </b-card-text>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-end"
      >
        <!-- chart -->
        <vue-apex-charts
          v-if="!!series"
          height="120"
          :options="earningsChart.chartOptions"
          :series="[series, 100 - series]"
        />
        <div
          v-else
          class="d-flex justify-content-center"
        >
          <img
            src="@/assets/images/icons/loading.gif" alt="loading"
            style="max-width: 100px; max-height 100px; object-fit: contain; width: 50%"
          >
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
// const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    series: {
      type: Number,
      default: 0,
    },
    type: {
      type: Boolean,
      default: true,
    },
    timeSpent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chart: false,
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Выполнено', 'Не Выполнено'],
          stroke: { width: 0 },
          colors: [$themeColors.success, $earningsStrokeColor2],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                    // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Выполнено',
                    formatter(val) {
                      console.log(val)
                      return `${val.config.series[0]}%`
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    series() {
      this.chart = false
      this.$nextTick(() => {
        if (this.series || this.series === 0) {
          this.chart = true
        }
      })
    },
  },
  methods: {
    dataBetween(start, end) {
      const startDate = new Date(start)

      const endDate = new Date(end)

      const diffTime = Math.abs(endDate - startDate)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
  },
}
</script>

<style scoped>

.echarts {
  min-height: 120px!important;
}
</style>
